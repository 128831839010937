<template>
  <v-card class="px-12" flat>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="auto">
            <span class="title">Gerais</span>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <mf-toggle v-model="data.active" hideDetails label="Landing Page - Redes"></mf-toggle>
      </v-col>
      <v-col cols="4">
        <v-row no-gutters>
          <v-col>
            <span class="label">Código</span>
          </v-col>
          <v-col>
            <v-row no-gutters justify="end">
              <v-col cols="auto">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on" v-on:click="copyUrl">
                      <mf-icon small icon="help"></mf-icon>
                    </div>
                  </template>
                  <span> URL preview https://compreonline.mercafacil.com/{{ data.identifier }} </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <mf-text-input v-model="data.identifier" class="mt-2" :rules="[notEmptyRule, specialCharacterRule]" label="Código da Landing Page"></mf-text-input>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" class="mb-0 pb-0">
            <span class="label">Cores</span>
          </v-col>
          <v-col>
            <color-picker
              :color.sync="data.colorPrimary.primary"
              :rules="[notEmptyRule, validHexcolor]"
              :contrast-text-color="data.colorPrimary.textColor"
              label="Cor Primária"
              edit
            ></color-picker>
          </v-col>
          <v-col>
            <color-picker :color.sync="data.colorPrimary.primaryLight" :rules="[notEmptyRule, validHexcolor]" label="Cor Light" edit></color-picker>
          </v-col>
          <v-col>
            <color-picker :color.sync="data.colorPrimary.textColor" :rules="[notEmptyRule, validHexcolor]" label="Texto" edit></color-picker>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { debounceTime } from '@/helpers/debounceTime'
import { genHexLightDark } from '@/helpers/genHexLightDark'
import { readableColor } from 'polished'
export default {
  name: 'CardInfoLandingPage',
  components: {
    ColorPicker: () => import('@/components/colors/ColorPicker.vue')
  },
  props: {
    dataLandingPage: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: this.dataLandingPage,
      notEmptyRule: v => !!v || 'Campo obrigatório',
      validHexcolor: v => /^#([0-9A-Fa-f]{8}|[0-9A-Fa-f]{6})$/.test(v) || 'Cor inválida',
      specialCharacterRule: v => !/[^A-Za-z0-9_\-]/.test(v) || 'Código não pode conter caracteres especiais'
    }
  },
  watch: {
    data: {
      handler() {
        this.$emit('input', this.data)
      },
      deep: true
    },
    dataLandingPage: {
      handler() {
        this.data = this.dataLandingPage
      },
      deep: true
    },
    'data.colorPrimary.primary': function(color, oldColor) {
      this.onAfterColorChange(color, oldColor, () => {
        const [baseLight] = genHexLightDark(color)
        this.data = {
          ...this.data,
          colorPrimary: {
            primary: this.data.colorPrimary.primary,
            primaryLight: baseLight,
            textColor: this.customReadableColor(color)
          }
        }
      })
    }
  },
  methods: {
    onAfterColorChange(color, oldColor, cb) {
      if (color && oldColor && color !== oldColor) {
        debounceTime(() => {
          return cb()
        }, 400)
      }
    },
    customReadableColor(color) {
      return readableColor(color, '#000000', '#FFFFFF')
    },
    copyUrl() {
      try {
        navigator.clipboard.writeText(`https://compreonline.mercafacil.com/${this.data.identifier}`)
        this.$alert({
          alert_message: 'URL copiada',
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.error(err.message)
      }
    }
  }
}
</script>
